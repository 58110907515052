'use client';

import { Mixpanel } from '../lib/mixpanel';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
export default function Analytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    Mixpanel.track(`Page View ${pathname}`, { url, tag: 'page-view' });
  }, [pathname, searchParams]);
  return null;
}
