var mixpanel = require('mixpanel-browser');
mixpanel.init('ad3d79def74c751d9e5e91b50eebb662');

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: any) => {
    mixpanel.track(name, props);
  },
  track_links: (selector: string, name: string, props?: any) => {
    mixpanel.track_links(selector, name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    }
  }
};
